import React, { Fragment } from 'react'

export default ({
  label,
  value,
}) => {
  if (!label || !value) {
    return null
  }

  if (Array.isArray(value)) {
    return value.length > 0 &&
      <Fragment>
        <dt>{label}</dt>
        {/*value.map(x =>
          <dd>{x}</dd>
        )*/}
        <dd>{value.join(", ")}</dd>
      </Fragment>
  }

   return <Fragment>
    <dt>{label}</dt>
    <dd>{value}</dd>
  </Fragment>
}
