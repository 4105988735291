import React from 'react'
import { Link } from 'gatsby'

export default ({
  showIndex,
  prev,
  next,
}) =>
  <ul className="list-unstyled d-flex flex-row justify-content-between">
    <li>
      { prev ? (
        <Link to={prev.url} rel="prev" title="Previous post">
          ← {prev.title}
        </Link>
      ) :(
        <Link to="/portfolio/" rel="up" title="Portfolio Index">
          ↑	Index
        </Link>
      )}
    </li>
    {showIndex &&
      <li>
        <Link to="/portfolio/" rel="index">
          <i className="fa fa-home" />
        </Link>
      </li>
    }
    <li>
      { next ? (
        <Link to={next.url} rel="next" title="Next post">
          {next.title} →
        </Link>
      ) :(
        <Link to="/portfolio/" rel="up" title="Portfolio Index">
          Index ↑
        </Link>
      )}
    </li>
  </ul>
