import React from "react"
import { graphql } from "gatsby"
import Helmet from 'react-helmet'

import SwirlContainer from '../container-fluid-swirl'
import DefinitionPair from './definition-pair'
import PrevNext from './prev-next'
import Layout from '../layout'

import './post.scss'

const meta_labels = {
  date: "Added",
  tags: "Tags",
  categories: "Categories",
  dimensions: "Dimensions",
  medium: "Medium",
  price: "Price",
}

const not_empty = x => x

const build_head_from = (frontmatter, excerpt, siteMetadata, prev, next) => {
  const { baseUri, title } = siteMetadata

  const head = {
    title: `${frontmatter.title}, ${frontmatter.medium} - ${title}`,

    link: [
      { rel: "canonical", href: baseUri + frontmatter.url },
      prev &&
        { rel: "prev", href: baseUri + prev.url },
      next &&
        { rel: "next", href: baseUri + next.url },
    ].filter(not_empty),

    meta: [
      excerpt &&
        { property: "description", content: excerpt },

      { property: "og:site_name", content: title },
      { property: "og:type", content: "article" },
      { property: "og:title", content: frontmatter.title },
      { property: "og:url", content: baseUri + frontmatter.url },
      frontmatter.featured_image &&
        { property: "og:image", content: baseUri + frontmatter.featured_image },
      { property: "og:locale", content: "en_NZ" },
      excerpt &&
        { property: "og:description", content: excerpt },

      { name: "twitter:card", content: "summary" },
      { name: "twitter:site", content: "@rinabotha" },

      { name: "fb:app_id", content: "599974110067433" },
      { name: "fb:profile_id", content: "529956827" },
      { name: "article:author", content: "https://www.facebook.com/rina.transom" },
    ].filter(not_empty)
  }

  if (frontmatter.categories) {
    frontmatter.categories.forEach(
      categoryName =>
        head.meta.push({ name: "article:section", content: categoryName})
    )
  }

  if (frontmatter.tags) {
    frontmatter.tags.forEach(
      tagName =>
        head.meta.push({ name: "article:tag", content: tagName})
    )
  }

  return head
}

export default ({
  data,
  pageContext,
}) => {
  const { site, markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html, excerpt } = markdownRemark
  const { prev, next } = pageContext

  return <Layout>
    <Helmet {...build_head_from(frontmatter, excerpt, site.siteMetadata, prev, next)} />

    <SwirlContainer>
      <nav className="BlogHeader-nav">
        <PrevNext prev={prev} next={next} showIndex={false} />
      </nav>
    </SwirlContainer>

    <section className="container">
      <article className="post-content">
        <header className="pb-2 my-4 border-bottom">
          <h1>{frontmatter.title}</h1>
        </header>

        <div className="row">
          <main
            className="col-lg post-markdown clearfix"
            dangerouslySetInnerHTML={{ __html: html }}
          />

          <aside className="col-lg-3 text-lg-right">
            <h3>Details</h3>
            <dl>
              {Object.keys(frontmatter).map(x =>
                <DefinitionPair
                  key={x}
                  label={meta_labels[x]}
                  value={frontmatter[x]}
                />
              )}
            </dl>
          </aside>
        </div>
      </article>

      <nav className="my-4">
        <PrevNext prev={prev} next={next} showIndex={true} />
      </nav>
    </section>
  </Layout>
}

export const pageQuery = graphql`
  query($path: String!) {
    site {
      siteMetadata {
        title
        baseUri
      }
    }
    markdownRemark(frontmatter: { url: { eq: $path } }) {
      frontmatter {
        url
        date(formatString: "Do MMMM YYYY")
        title
        featured_image
        medium
        dimensions
        price
        tags
        categories
      }
      excerpt
      html
    }
  }
`

